<template>
    <div>
        <s-form :item="item" />
    </div>
</template>

<script>
import sForm from './sForm'
export default {
    name: "add",
    components:{
        sForm
    },
    data(){
        let category = this.$route.params.category
        return {
            item: {
                categoryId: category,
                title:null,
                text:null,
                fileName:null
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
